import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import Style from "../RightSlide/RightSlide.module.css"
import Select from 'react-select';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createUser, deletUser, listUser, updateUser } from '../../../controllers/user'
import { isAuthenticated } from '../../../auth';
import { listCategory } from '../../../controllers/category';
import { listLocation } from '../../../controllers/location';
import { listTag } from '../../../controllers/tag';
import { listAd } from '../../../controllers/ad';
import IconButton from '@mui/material/IconButton';


import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';


export default function RightSlideCopy({adsLength,setAds, ads, ciityId, CheckValue, itemId, refersh, setRefresh }) {

    const { token, id, permission } = isAuthenticated();

    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const [edit, setEdit] = useState(false)
    const [AdCampain, setAdCampain] = useState({
        ad_campaign_name: "",
        ad_client_name: "",
        ad_placement: adsLength+1,
        ad_ad_url: "",
        ad_start_date: "",
        ad_expiry_date: "",
        city_id: ciityId
    })


    const [images, setImages] = useState({
        banner_image: null,
    })
    const [previewURL, setPreviewURL] = useState('');
    const [fileList, setFileList] = useState([]);

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        if (CheckValue === 2 && open === true) {
            handleEdit(itemId)
        }

        setState({ ...state, [anchor]: open });
    };

    const closeDrawer = (anchor, open) => (event) => {

        setState({ ...state, [anchor]: open });
    }


    const handleEdit = async (id) => {
        // console.log("run");
        setEdit(true);
        const data = await ads.filter(item => item._id === id)
        // console.log(data);
        setAdCampain({
            ...AdCampain,
            ad_campaign_name: data[0]?.ad_campaign_name || "",
            ad_client_name: data[0]?.ad_client_name || "",
            ad_ad_url: data[0]?.ad_ad_url || "",
            ad_placement: data[0]?.ad_placement || "",
            ad_expiry_date: data[0]?.ad_expiry_date?.split('T')[0] || "",
            ad_start_date: data[0]?.ad_start_date?.split('T')[0] || ""
        })
        setPreviewURL(data[0]?.ad_images.banner_image)
        setImages({
            ...images,
            banner_image: data[0]?.ad_images.banner_image
        })
        if(data[0]?.ad_images.banner_image){
            const url = { url: data[0]?.ad_images.banner_image || "" }
            setFileList([url])
        }
        else{
            setFileList([])
        }
    };


    // Form 


    const handleInputChange = (event) => {
        const currentDate = new Date().toISOString().split('T')[0];
        const { name, value } = event.target;

        if (name === 'ad_start_date' && value < currentDate) {
            return;
        }

        if (name === 'ad_expiry_date' && value < AdCampain.ad_start_date) {
            return;
        }

        setAdCampain({
            ...AdCampain,
            [name]: value
        });
    };


    const handleFileChange = (event) => {
        setImages((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.files[0],
        }));
        const reader = new FileReader();
        reader.onload = () => {
            setPreviewURL(reader.result);
        };
        reader.readAsDataURL(event.target.files[0]);
    };
    const [emptyFieldError, setEmptyFieldError] = useState()
    const handleFormSubmit = async (event) => {
        event.preventDefault();
        // console.log(AdCampain);
        const startDate = new Date(AdCampain.ad_start_date);
        const expiryDate = new Date(AdCampain.ad_expiry_date);
        if(startDate > expiryDate)
        {
            setEmptyFieldError("Expiry date cannot be less than start date")
            return
        }

        if (edit === false) {
            const data = new FormData();
            for (const key in AdCampain) {
                data.append(key, AdCampain[key]);
            }
            data.append('banner_image', fileList[0]?.originFileObj);
            // console.log(AdCampain);
            await fetch(`${process.env.REACT_APP_API_URL}/ad/create?staff=${id}`, {
                method: 'POST',
                body: data
            })
                .then(response => {
                    if (response.error) {
                        console.log(response.error);
                    } else {
                        setAdCampain({
                            ...AdCampain,
                            ad_campaign_name: "",
                            ad_client_name: "",
                            ad_placement: adsLength+1,
                            ad_ad_url: "",
                            ad_start_date: "",
                            ad_expiry_date: "",
                            city_id: ciityId
                        })
                        setFileList([])
                        toast.success('Ad Campaign Added Successfully', {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                })
                .catch(error => {
                    // Handle error
                });
        }
        else {
            const data = new FormData();
            for (const key in AdCampain) {
                data.append(key, AdCampain[key]);
            }
            console.log(typeof images.banner_image); // Check the type of images.banner_image

            if (typeof fileList[0]?.originFileObj === "object") {
                data.append("image_edited", 1)
                // console.log("Edit true")
                data.append('banner_image', fileList[0]?.originFileObj);
            }
            else {
                // console.log("Edit false");
                data.append("image_edited", 0)
            }
            await fetch(`${process.env.REACT_APP_API_URL}/ad/update?id=${itemId}&staff=${id}`, {
                method: 'PUT',
                body: data
            })
                .then(response => {
                    if (response.error) {
                        console.log(response.error);
                    } else {
                        toast.success('Ad Campaign Updated Successfully', {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                })
                .catch(error => {
                    // Handle error
                });
        } setRefresh(!refersh)
        await listAd(id, ciityId, token).then(data => {
            setAds(data)
        }).catch(error => console.error(error));
    }


    // console.log('image', previewURL)
    const [imageSizeError,setImageSizeError] = useState()
    const onChange = ({ fileList: newFileList }) => {
        // console.log(newFileList);
        const maxSize = 1 * 1024 * 1024
        if(newFileList.length > 0 && newFileList[0].size > maxSize){
            setImageSizeError("Image size should be less than 1 MB")
            return
        }
        let x;
        if (fileList.length > 1) {
            x = fileList.filter(data => data.uid !== fileList[0].uid)

        } else { x = newFileList }
        // console.log(x);
        setFileList(x)
        setImageSizeError()
    };


    const list = (anchor) => (
        <Box
            className={Style.rightSlide}
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 500 }}
            role="presentation"
        >
            <div className='rightSlide_head'><span onClick={toggleDrawer(anchor, false)} ><IconButton><CloseOutlinedIcon sx={{ color: 'white' }} /></IconButton></span><p>Create Ad</p></div>
            <div className={Style.rightSlide_form}>
                <form onSubmit={handleFormSubmit}>
                    <div className={Style.rightSlide_formInput}>
                        <TextField className={Style.rightSlide_formInput_input} name="ad_campaign_name" value={AdCampain.ad_campaign_name} id="outlined-basic-1" label="Enter Campaign Name *" variant="outlined" onChange={handleInputChange} />
                        <TextField className={Style.rightSlide_formInput_input} name="ad_client_name" value={AdCampain.ad_client_name} id="outlined-basic-2" label='Enter Client Name *' variant="outlined" onChange={handleInputChange} />
                        <TextField disabled className={Style.rightSlide_formInput_input} name="ad_placement" value={AdCampain.ad_placement} id="outlined-basic-3" label='Placement *' variant="outlined" onChange={handleInputChange} />
                        <TextField className={Style.rightSlide_formInput_input} name="ad_ad_url" value={AdCampain.ad_ad_url} id="outlined-basic-4" label='Ad URL *' variant="outlined" onChange={handleInputChange} />
                    </div>


                    <div className={Style.rightSlide_formDate_startDate}>
                        <p>Start Date *:</p>
                        <TextField className={Style.rightSlide_formInput_input} value={AdCampain.ad_start_date} type='date' id="outlined-basic-6" name='ad_start_date' variant="outlined" onChange={handleInputChange} />
                    </div>
                    <div className={Style.rightSlide_formDate_endDate}>
                        <p>Expires Date *:</p>
                        <TextField className={Style.rightSlide_formInput_input} value={AdCampain.ad_expiry_date} type='date' id="outlined-basic-7" name='ad_expiry_date' variant="outlined" onChange={handleInputChange} />
                    </div>



                    <ImgCrop modalClassName="custom-ant-modal-weekendo" aspect={5/1} modalOk="Crop" showGrid showReset>
                        <Upload
                            listType="picture-card"
                            fileList={fileList}
                            onChange={onChange}

                        >
                            {fileList.length !== 1 && '+ Upload'}
                        </Upload>
                    </ImgCrop>
                    {imageSizeError && <p style={{ color: "red", fontSize: "14px" }}>{imageSizeError}</p>}





                    {/* <div className={Style.rightSlide_formImage} style={{ display: 'flex', flexDirection: 'column' }} >
                        Image:

                        {images.banner_image ? <><img src={previewURL} alt="" /><p style={{ color: 'green', fontWeight: '500', cursor: 'pointer' }} >images.banner_image.name</p></> : <><label style={{ color: "green", fontWeight: '500', cursor: 'pointer' }} ><TextField type="file" id="banner_image" name="banner_image" onChange={handleFileChange} style={{ display: 'none' }} />upload Image</label></>}
                        {images.banner_image ? <p style={{ padding: '10px 0px', color: 'red', cursor: 'pointer' }} onClick={() => { setImages({ ...setImages, banner_image: null }) }} >remove</p> : ''}
                    </div> */}
                    {emptyFieldError && <p style={{ color: "red", fontSize: "14px" }}>{emptyFieldError}</p>}
                    <div className={Style.rightSlide_formSubmit}>
                        <Button onClick={closeDrawer(anchor, false)} variant="contained" type='submit' style={{ marginTop: '10px' }} className='addBtn'>{CheckValue === 1 ? "Create Ad" : "Update Ad"}</Button>
                    </div>
                </form>
            </div>
        </Box>
    );

    // console.log(CheckValue)

    return (
        <div>
            {['right'].map((anchor) => (
                <React.Fragment key={anchor}>
                    {CheckValue === 1 ? <><Button onClick={toggleDrawer(anchor, true)} className='addBtn' variant="contained">Create Ads</Button>
                    </> : CheckValue === 2 ? <><span className='hoverIcon' onClick={toggleDrawer(anchor, true)}> <IconButton><BorderColorOutlinedIcon /></IconButton></span>
                    </> : ''}

                    <SwipeableDrawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {list(anchor)}
                    </SwipeableDrawer>
                </React.Fragment>
            ))}
            {/* <ToastContainer /> */}
        </div>
    );
}