import React, { useEffect, useState } from "react";
import { fetchApi } from "../../controllers/dashboardAnalytics";
// import Navbar from '../../components/SideBar/SideBar'
import Style from "./dashboard.module.css";
import { isAuthenticated } from "../../auth/index";
import { listLocation } from "../../controllers/location";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import EmailAnalyticsChart from "./Chart/EmailAnalyticsChart";
import TextField from "@mui/material/TextField";

const Dashboard = () => {
  const [AnalyticsData, setAnalyticsData] = useState();
  const { token, id, permission } = isAuthenticated();
  const [locations, setlocations] = useState([]);
  const [locationId, setlocationId] = useState("");
  const [noofdays, setnoofdays] = useState(0);

  const [filterDate, setFilterDate] = useState({
    from: "",
    to: "",
  });

  const init = () => {
    const data_to_send = {
      cityId: locationId === "All City" ? "" : locationId,
      noofdays: noofdays,
      filterDate: filterDate,
    };

    fetchApi(token, data_to_send)
      .then((data) => {
        // console.log(data);
        setAnalyticsData(data);
      })
      .catch((error) => console.error(error));

    listLocation(id, token)
      .then((data) => {
        // console.log(data);
        setlocations(data);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    init();
  }, [locationId, noofdays, filterDate]);

  const timeTravel = [
    { value: 0, name: "Life Time" },
    { value: 7, name: "Last 7 Days" },
    { value: 15, name: "Last 15 Days" },
    { value: 30, name: "Last 30 Days" },
    { value: 60, name: "Last 60 Days" },
    { value: "Custom", name: "Custom" },
  ];

  const Boxs = [
    "Total New Users",
    "Total Emails Sent",
    "Open Rate",
    "Bounce Rate",
  ];

  return (
    <div className={Style.dashboard}>
      {/* <Navbar/> */}
      <div className={Style.dashboardHead}>
        <h3>Analytics</h3>
        <div className={Style.dashboardFilter}>
          <div className="" style={{ width: "100%" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">City</InputLabel>
              <Select
                label="City"
                value={locationId}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={(e) => setlocationId(e.target.value)}
              >
                <MenuItem value="All City">All City</MenuItem>
                {locations.length > 0 &&
                  locations?.map((item) => (
                    <MenuItem value={item._id}>{item.locationName}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className="" style={{ width: "100%" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Time</InputLabel>
              <Select
                variant="outlined"
                label="Time"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={(e) => {
                  setnoofdays(e.target.value);
                  setFilterDate({
                    ...filterDate,
                    from: "",
                    to: "",
                  });
                }}
                value={noofdays}
                sx={{ padding: "0px" }}
              >
                {timeTravel.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {noofdays === "Custom" && (
            <div className="" style={{ width: "100%" }}>
              <FormControl fullWidth>
                {/* <InputLabel id="from">From</InputLabel> */}
                <TextField
                  className={Style.rightSlide_formInput_input}
                  value={filterDate?.from}
                  type="date"
                  id="from"
                  name="from"
                  placeholder="dssdsd"
                  variant="outlined"
                  label="From"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFilterDate({
                      ...filterDate,
                      from: e.target.value,
                      to: "",
                    });
                  }}
                />
              </FormControl>
            </div>
          )}

          {noofdays === "Custom" && (
            <div className="" style={{ width: "100%" }}>
              <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label">To</InputLabel> */}
                <TextField
                  className={Style.rightSlide_formInput_input}
                  value={filterDate?.to}
                  label="To"
                  type="date"
                  id="outlined-basic-7"
                  name="to"
                  variant="outlined"
                  onChange={(e) =>
                    setFilterDate({ ...filterDate, to: e.target.value })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...(filterDate.from && {
                    inputProps: { min: filterDate.from },
                  })}
                />
              </FormControl>
            </div>
          )}
        </div>
      </div>

      <div className={Style.dashboardBoxs}>
        <div className={Style.Boxs}>
          <p>{Boxs[0]}</p>
          <h3>{AnalyticsData?.new_users}</h3>
          {/* <span style={{fontSize:'12px'}} >201 Orders</span> */}
        </div>
        <div className={Style.Boxs}>
          <p>{Boxs[1]}</p>
          <h3>{AnalyticsData?.NewslettersCount}</h3>
          {/* <span style={{ fontSize: '12px' }} >201 Orders</span> */}
        </div>
        <div className={Style.Boxs}>
          <p>{Boxs[2]}</p>
          <h3>
            {AnalyticsData
              ? `${(AnalyticsData.event_open_rate * 1).toFixed(2)}%`
              : ""}
          </h3>
          <span style={{ fontSize: "12px" }}>
            {AnalyticsData?.sum_of_email_opened_count_unique_user} Times Opened
          </span>
        </div>
        <div className={Style.Boxs}>
          <p>{Boxs[3]}</p>
          <h3>{AnalyticsData?.email_bounced_rate}%</h3>
          <span style={{ fontSize: "12px" }}>
            {AnalyticsData?.total_email_bounced} Emails Bounced
          </span>
          {/* <span style={{ fontSize: '12px' }} >201 Orders</span> */}
        </div>
      </div>
      <br />
      {AnalyticsData && (
        <EmailAnalyticsChart
          graphData={AnalyticsData?.graphData}
          locationId={locationId}
          noofdays={noofdays}
        />
      )}

      <div className={Style.dashboardBody}>
        <div className={Style.dashboardBodyAnalytic_no}>
          <div
            className={`${Style.dashboardBodyAnalytic_noMail} ${Style.AnalyticsData_box}`}
          >
            <strong>Email Analytics</strong>
            <div className={`${Style.dashboardBodyAnalytic_noMailData} `}>
              {/* <div className={Style.dashboardBodyAnalytic_noMailData_row}>
                <div className={Style.dashboardBodyAnalytic_noMailData_rowIcon}><TrendingUpIcon /></div>
                <p>{AnalyticsData && <>Total Users : <span>{AnalyticsData.total_users}</span></>}</p>
              </div>
              
              <div className={Style.dashboardBodyAnalytic_noMailData_row}>
                <div className={Style.dashboardBodyAnalytic_noMailData_rowIcon}><TrendingUpIcon /></div>
                <p>{AnalyticsData && <>Total New Users : <span>{AnalyticsData.new_users}</span></>}</p>
              </div> */}

              <div className={Style.dashboardBodyAnalytic_noMailData_row}>
                <div className={Style.dashboardBodyAnalytic_noMailData_rowIcon}>
                  <TrendingUpIcon />
                </div>
                <p>
                  {AnalyticsData && (
                    <>
                      Emails/ Newletters Sent :{" "}
                      <span>{AnalyticsData.NewslettersCount}</span>
                    </>
                  )}
                </p>
              </div>
              <div className={Style.dashboardBodyAnalytic_noMailData_row}>
                <div className={Style.dashboardBodyAnalytic_noMailData_rowIcon}>
                  <TrendingUpIcon />
                </div>
                <p>
                  {AnalyticsData && (
                    <>
                      Emails Opened (unique):{" "}
                      <span>
                        {AnalyticsData.sum_of_email_opened_count_unique_user}
                      </span>
                    </>
                  )}
                </p>
              </div>
              {/* <div className={Style.dashboardBodyAnalytic_noMailData_row}>
                <div className={Style.dashboardBodyAnalytic_noMailData_rowIcon}><TrendingUpIcon /></div>
                <p>{AnalyticsData && <>Email Opened (Total Times): <span>{AnalyticsData.sum_of_email_opened_count_total_times}</span></>}</p>
              </div> */}
              {/* <div className={Style.dashboardBodyAnalytic_noMailData_row}>
                <div className={Style.dashboardBodyAnalytic_noMailData_rowIcon}><TrendingUpIcon /></div>
                <p>{AnalyticsData && <>Website Visitors : <span>0</span></>}</p>
              </div> */}
              <div className={Style.dashboardBodyAnalytic_noMailData_row}>
                <div className={Style.dashboardBodyAnalytic_noMailData_rowIcon}>
                  <TrendingUpIcon />
                </div>
                <p>
                  {AnalyticsData && (
                    <>
                      Number of Ad Clicks :{" "}
                      <span>{AnalyticsData.ad_click_total}</span>
                    </>
                  )}
                </p>
              </div>
              <div className={Style.dashboardBodyAnalytic_noMailData_row}>
                <div className={Style.dashboardBodyAnalytic_noMailData_rowIcon}>
                  <TrendingUpIcon />
                </div>
                <p>
                  {AnalyticsData && (
                    <>
                      Number of Listing Clicks :{" "}
                      <span>{AnalyticsData.event_click_total}</span>
                    </>
                  )}
                </p>
              </div>
              <div className={Style.dashboardBodyAnalytic_noMailData_row}>
                <div className={Style.dashboardBodyAnalytic_noMailData_rowIcon}>
                  <TrendingUpIcon />
                </div>
                <p>
                  {AnalyticsData && (
                    <>
                      Number of Clicks on See More Options :{" "}
                      <span>
                        {AnalyticsData.explore_more_button_click_count_total}
                      </span>
                    </>
                  )}
                </p>
              </div>
              <div className={Style.dashboardBodyAnalytic_noMailData_row}>
                <div className={Style.dashboardBodyAnalytic_noMailData_rowIcon}>
                  <TrendingUpIcon />
                </div>
                <p>
                  {AnalyticsData && (
                    <>
                      Number Of Emails Bounced : <span>0</span>
                    </>
                  )}
                </p>
              </div>
              <div className={Style.dashboardBodyAnalytic_noMailData_row}>
                <div className={Style.dashboardBodyAnalytic_noMailData_rowIcon}>
                  <TrendingUpIcon />
                </div>
                <p>
                  {AnalyticsData && (
                    <>
                      Unsubscribe Rate :{" "}
                      <span>{AnalyticsData.unsubscrbeRate} %</span>
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>

          <div
            className={`${Style.dashboardBodyAnalytic_noPerformance} ${Style.AnalyticsData_box}`}
          >
            <strong>Performance Analytics</strong>
            <div className={`${Style.dashboardBodyAnalytic_noPerformanceData}`}>
              <div
                className={Style.dashboardBodyAnalytic_noPerformanceData_row}
              >
                <div
                  className={
                    Style.dashboardBodyAnalytic_noPerformanceData_rowIcon
                  }
                >
                  <TrendingUpIcon />
                </div>
                <p>
                  {AnalyticsData && (
                    <>
                      Total Users : <span>{AnalyticsData.total_users}</span>
                    </>
                  )}
                </p>
              </div>
              <div
                className={Style.dashboardBodyAnalytic_noPerformanceData_row}
              >
                <div
                  className={
                    Style.dashboardBodyAnalytic_noPerformanceData_rowIcon
                  }
                >
                  <TrendingUpIcon />
                </div>
                <p>
                  {AnalyticsData && (
                    <>
                      Total New Users : <span>{AnalyticsData.new_users}</span>
                    </>
                  )}
                </p>
              </div>
              <div className={Style.dashboardBodyAnalytic_noMailData_row}>
                <div className={Style.dashboardBodyAnalytic_noMailData_rowIcon}>
                  <TrendingUpIcon />
                </div>
                <p>
                  {AnalyticsData && (
                    <>
                      Total Tags : <span>{AnalyticsData.tags}</span>
                    </>
                  )}
                </p>
              </div>
              <div className={Style.dashboardBodyAnalytic_noMailData_row}>
                <div className={Style.dashboardBodyAnalytic_noMailData_rowIcon}>
                  <TrendingUpIcon />
                </div>
                <p>
                  {AnalyticsData && (
                    <>
                      Total Locations : <span>{AnalyticsData.locations}</span>
                    </>
                  )}
                </p>
              </div>
              <div className={Style.dashboardBodyAnalytic_noMailData_row}>
                <div className={Style.dashboardBodyAnalytic_noMailData_rowIcon}>
                  <TrendingUpIcon />
                </div>
                <p>
                  {AnalyticsData && (
                    <>
                      Total Categories : <span>{AnalyticsData.category}</span>
                    </>
                  )}
                </p>
              </div>
              <div className={Style.dashboardBodyAnalytic_noMailData_row}>
                <div className={Style.dashboardBodyAnalytic_noMailData_rowIcon}>
                  <TrendingUpIcon />
                </div>
                <p>
                  {AnalyticsData && (
                    <>
                      Numbers Of Ads Running :{" "}
                      <span>{AnalyticsData.adRunning}</span>
                    </>
                  )}
                </p>
              </div>
              <div className={Style.dashboardBodyAnalytic_noMailData_row}>
                <div className={Style.dashboardBodyAnalytic_noMailData_rowIcon}>
                  <TrendingUpIcon />
                </div>
                <p>
                  {AnalyticsData && (
                    <>
                      Number Of Team Members :{" "}
                      <span>{AnalyticsData.staff}</span>
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
