import React, { useEffect, useState } from 'react'
// import Navbar from '../../components/SideBar/SideBar'
import { listLocation } from '../../controllers/location'
import { isAuthenticated } from '../../auth'
import { deletEvent, listEvent } from '../../controllers/newsletter'
import Addevent from '../../components/addevent'
import Style from "./newsletter.module.css"
import Table from '../../components/Tables/Table'
import Button from '@mui/material/Button';
import RightSlide from './RightSlide/RightSlide'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MailPreviewPage from '../MailPreviewPage/MailPreviewPage'
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IconButton from '@mui/material/IconButton';
import Accordian from "./Accordian/Accordian"

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Filter from './filter'
import { listCategory } from '../../controllers/category'

import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { get_staff_by_id } from '../../controllers/staff'
import { Result, Spin } from 'antd';
import Time from 'react-time-format'
import Chip from '@mui/material/Chip';
import moment from 'moment';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
const Newsletter = () => {

    const history = useHistory();
    const [show, setShow] = useState(1)



    const [role, setRole] = useState()
    const [permission, setPermission] = useState()
    const fetch_user_data = () => {
        get_staff_by_id(id).then(data => {
            if (data.error) { console.log(data.error); }
            else { setPermission(data.permission); setRole(data.tag) }
        })
    }

    useEffect(() => {
        fetch_user_data()
    }, [])










    const [cityName, setcityname] = useState()
    const [ciityId, setCityId] = useState('')

    const { token, id } = isAuthenticated();

    const [locationData, setLocationData] = useState([])
    const [refersh, setRefresh] = useState(false)
    const [filterUser, setFilteruser] = useState(false)
    const [filterCity, setFilterCity] = useState([])
    const [filterCategory, setFilterCategory] = useState([])
    const [Categorys, setCategorys] = useState([])
    // for listing all the locations
    const init = async () => {
        listLocation(id, token).then(data => {
            // console.log(data)
            setLocationData(data)
        }).catch(error => console.error(error));

        const filter = {
            city: []
        }

        listCategory(id, filter, token).then(data => {
            // console.log(data)
            setCategorys(data)
        }).catch(error => console.error(error));
    };

    useEffect(() => {
        init();
    }, [refersh]);

    const [events, setEvents] = useState([])


    const handleAddEvent = async (city_id, city_name) => {
        const filter = {
            category: filterCategory
        }
        await listEvent(id, filter, city_id, token).then(data => {
            setEvents(data)
        }).catch(error => console.error(error));

        // console.log(city_name);

        // console.log(events);
        setcityname(city_name)
        setCityId(city_id)
        setShow(2)
    }



    useEffect(() => {
        const filter = {
            category: filterCategory
        }
        listEvent(id, filter, ciityId, token).then(data => {
            setEvents(data)
        }).catch(error => console.error(error));
        // handleAddEvent(ciityId,cityName)
    }, [refersh])

    const url = process.env.REACT_APP_API_URL
    const handleSendMmail = async (cid) => {
        history.push(`/send-mail-preview/${cid}`);
    }


    // Table Content 

    // Select City Table 

    const TableHeading = ['City','Listing Count', <label style={{textAlign:"center",width:"30%"}}>Listing/Newsletter</label>]
    const keys = ['locationName','locationEvents', '_id']
    const filteredData = permission && locationData?.length > 0 && locationData?.filter(data => {
        if (role && role.toString() === process.env.REACT_APP_API_ADMIN_ID) {
            // console.log("data");
            return true; // Send all data for admin role
        } 
        
        else {
            return permission.allowedCities.includes(data._id); // Filter data for staff role
        }
    });
    const TableData = permission && filteredData && filteredData.map(item => keys.map(key => {
        if (key === "_id") {
            return (
                <>
                    {permission && permission.event.read === 1 && <Button variant="outlined"  onClick={() => handleAddEvent(item._id, item.locationName)} >Add Listing</Button>}
                    {permission && permission.event.read === 1 && permission.event.create === 1 && <Button variant="contained"  onClick={() => handleSendMmail(item._id)} >Preview</Button>}
                </>
            );
        } 
        if (key === "locationEvents") {
            return item?.locationEvents > 0 ? item?.locationEvents : 0
        }
        else {
            return item[key] || "";
        }
    }));

    // const TableData = permission && locationData?.length > 0 && locationData?.filter(data => {
    //     // permission.allowedCities.includes(data._id)

    //     if (role && role.toString() === "6496907b7a07903ee778c92b") {
    //         console.log("data");
    //         return ; // Send all data for admin role
    //     } else {
    //         return permission.allowedCities.includes(data._id); // Filter data for staff role
    //     }

    // }).map(item => keys.map(key => {
    //     if (key === "_id") {
    //         return <>
    //             {permission && permission.event.read === 1 && <Button className='addBtn' variant="contained" onClick={() => handleAddEvent(item._id, item.locationName)} >Add Listing</Button>}
    //             {permission && permission.event.read === 1 && permission.event.create === 1 && <Button className='addBtn' sx={{ background: 'rgba(91, 141, 215, 0.25)', color: '#1976d2' }} onClick={() => handleSendMmail(item._id)} >Preview</Button>}
    //         </>
    //     }
    //     else {
    //         return item[key] || "";
    //     }
    // })
    // );

    // Select City > Add Event Table


























    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteid] = useState()

    const handleClickOpen = (id) => {
        setDeleteid(id)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = (event_id) => {
        // console.log(id);
        deletEvent(id, event_id, token).then(data => {
            if (data.message) {
                handleClose()
                toast.success(data.message, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } if (data.error) {
                toast.error(data.error, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        })
        setRefresh(!refersh)
        setOpen(false)
    }

    // console.log('events', events)
   
    const [data, setData] = useState([]);

    const Keys = ['event_title', 'event_venue', 'event_start_date', 'event_end_date', 'event_expiry_date', 'status', '_id']
   
    const inits = () => {
        if (data.length > Categorys.length) { return }
        const array = []
        Categorys.map((item) => {
            const filteredEvents = events.length > 0 && events.sort((a, b) => new Date(a.event_start_date) - new Date(b.event_start_date)).filter((event) => event.event_category === item._id);
            const x = events.length > 0 && filteredEvents?.map(data => Keys.map(key => {
                if (key === "_id") {
                    return <>
                        {permission && permission.event.edit === 1 && <RightSlide refersh={refersh} setRefresh={setRefresh} itemId={data?._id} events={events} CheckValue={2} Categorys={Categorys} />}
                        {permission && permission.event.delete === 1 && <span className='hoverIcon'><IconButton><DeleteOutlineOutlinedIcon onClick={() => handleClickOpen(data._id)} /></IconButton></span>}
                    </>
                }
                if (key === 'status') {
                    const expiryDate = moment(data.event_expiry_date);
                    const currentDate = moment();
                    const startDate = moment(data.event_start_date)
                    const daysRemaining = expiryDate.diff(currentDate, 'days');
                    if (startDate < currentDate) {
                        if (daysRemaining <= 5 && daysRemaining >= 0) {
                            return <Chip label={`Expires in ${daysRemaining} day`} sx={{ backgroundColor: "#fc9803" }} />
                        }
                        else if (daysRemaining < 0) {
                            return <Chip label="Expired" sx={{ backgroundColor: "#fa5757", color: "white" }} />
                        }
                        else if (daysRemaining > 5) {
                            return <Chip label="Running" sx={{ backgroundColor: "#3bb317" }} />
                        }
                    }

                    else {
                        return <Chip label="Not Started" sx={{ backgroundColor: "White", border: "1px solid black" }} />
                    }
                }
                if (key === 'event_start_date') {
                    return <Time value={data.event_start_date} format="DD-MM-YYYY" />
                }
                if (key === 'event_end_date') {
                    return <Time value={data.event_end_date} format="DD-MM-YYYY" />
                }
                if (key === 'event_expiry_date') {
                    return <Time value={data.event_expiry_date} format="DD-MM-YYYY" />
                }
                else {
                    return data[key] || "";
                }
            }));
            const categoryData = {
                categoryName: item.categoryName,
                events: x
            };
            array.push(categoryData)
        });
        setData(array);
    }


    useEffect(() => {
        inits()
    }, [refersh, events])


    const [loading, setLoading] = useState(true)
    useEffect(()=>{
        setLoading(true);
      
        const timeout = setTimeout(() => {
          setLoading(false);
        }, 100);
      
        return () => clearTimeout(timeout);
    },[show])
  
    return (
        <>

            {/* Select City  */}
            {loading ?
                <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spin size='large' />

                </div> :

                <> 
                 {show === 1 && <>
                <div className={Style.newsletterCityName}>
                    <div className={Style.newsletterCityNameHead}>
                        <h3>Newsletters</h3>

                    </div>
                    {locationData.length !== 0 ?
                        <>
                            <div className={Style.newsletterCityNameTable}>
                                <Table TableData={TableData} TableHeading={TableHeading} />
                            </div>
                        </>
                        :
                        <>
                            No Cities Availlable
                        </>}
                </div>
            </>}
                
                
                </>}
           





            {/* Select City > Add Event  */}
            {loading ?
                <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spin size='large' />

                </div> :

                <>

                    {show === 2 && <>
                        {permission && permission.event.read === 1 ?
                            <div className={Style.newsletterCityEventCreation}>
                                <div className={Style.newsletterCityEventCreationHead}>
                                    <p className='headDetail' >
                                        <h3><IconButton onClick={() => setShow(1)}><ArrowBackIosIcon fontSize='small' /></IconButton>
                                            <span onClick={() => setShow(1)} style={{ color: '#1976d2', cursor: 'pointer' }} >{cityName.charAt(0).toUpperCase() + cityName.slice(1)}</span>
                                            {/* &gt; Listing */}
                                        </h3>
                                        <span className='totalNumbers' >Total Listing : {events.length}</span>

                                    </p>
                                    <div className={Style.topButtons}  >
                                        {/* <Filter
                                    filterCategory={filterCategory}
                                    setFilterCategory={setFilterCategory}
                                    filterUser={filterUser}
                                    setFilteruser={setFilteruser}
                                    Categorys={Categorys}
                                /> */}
                                        {permission && permission.event.create === 1 && <RightSlide Categorys={Categorys} refersh={refersh} setRefresh={setRefresh} setEvents={setEvents} CheckValue={1} cityName={cityName} ciityId={ciityId} />}
                                    </div>
                                </div>
                                {!loading && Categorys && data.length !== 0 ?
                                    <>
                                        <Accordian data={data} />
                                    </>
                                    :
                                    <>
                                        No Events Added
                                    </>
                                }

                            </div> :
                            <Result
                                status="403"
                                title="403"
                                subTitle="Sorry, you are not authorized to access this page."
                            />
                        }

                    </>
                    }

                </>

            }


            <ToastContainer />

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure want to delete this Listing
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" onClick={() => handleDelete(deleteId)} >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Newsletter