import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { forgot_password_change_password } from '../../controllers/forgotPassword';
import Style from "./Forgetpassword.module.css"
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';



const ForgotPasswordChangeMail = () => {

  const history = useHistory();

  const { emails, tokens } = useParams();
  const [password, setPassword] = useState()
  const [cpassword, setcpassword] = useState()
  const [email, setemail] = useState(emails)
  const [token, settoken] = useState(tokens)
  const [error, setError] = useState()

  // console.log(emails);


  const handleSubmit = async () => {
    const data = {
      password: password,
      cpassword: cpassword,
      email: email,
      token: token
    }
    // console.log(data);
    await forgot_password_change_password(data).then(data => {
      if (data.error) {
        setError(data.error)
      }
      else {
        history.push(`/`)
      }
    })
  }

  const textfieldStyle = {
    width: '300px',
    margin: '10px 0'
  }

  const btnStyle = {
    width: '300px',
    height: '50px',
    margin: '10px 0',
    backgroundColor: '#4287f5',

  }

  return (
    <>
      <div className={`${Style.admin_hireflax_form_container} flex flex-wrap flex-ac`}>

        <div className={`${Style.admin_forimage}`}>
          <img src="\Assets\ForgetPasswordPage\ForgetPasswordIMG.jpg" alt="SignIn" />
        </div>
        <div className={`${Style.admin_login_form} flex flex-column flex-ac flex-jcc`}>
          <img src="\Assets\MailAsset\Logo.svg" alt="logo" width="45%" />
          <div className={`${Style.admin_login_heading} flex`}>
            <span>Enter New Password</span>
          </div>
          <div className={`${Style.form_container} flex flex-column`}>
            <div>
              <div className="flex flex-column">
                <TextField
                  id="standard-search"
                  label="Enter New Password"
                  type='password'
                  name='password' value={password} onChange={(e) => setPassword(e.target.value)}
                  variant="standard"
                  style={textfieldStyle}
                  required
                />

                <TextField
                  id="filled-password-input"
                  label="Confirm New Password"
                  type='password'
                  name='cpassword' value={cpassword} onChange={(e) => setcpassword(e.target.value)}
                  autoComplete="current-password"
                  variant="standard"
                  style={textfieldStyle}
                  required
                />
                <Button
                  endIcon={<ArrowForwardRoundedIcon />}
                  loadingPosition="end"
                  variant="contained"
                  style={btnStyle}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
                <div style={{ width: '300px'}}>
                  {error && <p style={{ color: "red", lineHeight: '150%' }}>{error}</p>}
                </div>
              </div>

            </div>
            <br />

          </div>
        </div>
      </div>














    </>
  )
}

export default ForgotPasswordChangeMail
