import React, { useEffect, useState } from 'react'
import { createTag, deletTag, editTag, listTag } from '../../controllers/tag'
import { isAuthenticated } from '../../auth'
import RightSlide from "./RightSlide/RightSlide"
import Style from "../Tag/Tags.module.css"
import Table from "../../components/Tables/Table"
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Time from 'react-time-format'
import IconButton from '@mui/material/IconButton';
import { listUser } from '../../controllers/user'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { get_staff_by_id } from '../../controllers/staff'
import { Result, Spin } from 'antd';
const Tag = () => {
    const [tags, setTags] = useState([])
    const [Check, setCheck] = useState(false)
    // const handleChange = (e) => {
    const [refersh, setRefresh] = useState(true)

    const { token, id } = isAuthenticated();

    // for listing all the tags
    const init = async () => {
        listTag(id, token).then(data => {
            // console.log(data)
            setTags(data)
        }).catch(error => console.error(error));
    };

    // for deleting tag
    const [permission, setPermission] = useState()
    const fetch_user_data = () => {
        get_staff_by_id(id).then(data => {
            if (data.error) { console.log(data.error); }
            else { setPermission(data.permission) }
        })
    }

    useEffect(() => {
        fetch_user_data()
    }, [])


    const handleDelete = (tag_id) => {
        deletTag(id, tag_id, token).then(data => {
            if (data.message) {
                handleClose()
                toast.success(data.message, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } if (data.error) {
                toast.error(data.error, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            init();
        })

    }

    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteid] = useState()

    const handleClickOpen = (id) => {
        setDeleteid(id)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // console.log(deleteId);


    // Table Content 

    const TableHeading = [
        'Tag Name', 'Created By', 'Created On', 'Actions'
    ]

    // Define the specific keys in the desired order
    const keys = ["tagName", "createdBy", "createdAt", "_id"];

    // Extract the values for the specified keys and arrange them in a 2D array
    const TableData = tags.length > 0 && tags.map(item => keys.map(key => {
        if (key === 'tagName') {
            return (
                <span style={{ display: "flex", alignItems: "center" }}>
                    {item.tagName}&nbsp;&nbsp;<IconButton><Tooltip title={item.description}><HelpOutlineIcon sx={{ color: "rgba(91, 141, 215, 1) !important", cursor: "pointer", fontSize: "20px" }} /></Tooltip></IconButton>
                </span>
            )
        }
        if (key === "_id") {
            return <>
                {permission && permission.tag.edit === 1 && <RightSlide tags={tags} refersh={refersh} setRefresh={setRefresh} itemId={item?._id} CheckValue={2} />}
                {permission && permission.tag.delete === 1 && item.tagName !== "user" && item.tagName !== "staff" && item.tagName !== "admin" &&
                    <span className='hoverIcon'><IconButton><DeleteOutlineOutlinedIcon onClick={() => handleClickOpen(item._id)} /></IconButton></span>
                }
            </>
        }
        if (key === 'createdAt') {
            return <Time value={item.createdAt} format="DD-MM-YYYY" />
        }
        if (key === 'createdBy') {
            // console.log(item.createdBy)
            return `${item.createdBy?.name?.firstName} ${item.createdBy?.name?.lastName}`
        }
        else {
            return item[key] || "";
        }

    }));

    useEffect(() => {
        init();
    }, [refersh]);




    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true);

        const timeout = setTimeout(() => {
            setLoading(false);
        }, 500);

        return () => clearTimeout(timeout);
    }, [permission])



    return (
        <>
            {loading ? <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Spin size='large' />

            </div> :
                <>
                    {permission && permission.tag.read === 1 ?
                        <div className={Style.tags}>


                            <div className={Style.tagsHead}>
                                <p className='headDetail' >
                                    <h3>Tags</h3>
                                    <span className='totalNumbers' >Total Tags : {tags.length}</span>
                                </p>
                                {permission && permission.tag.create === 1 && <RightSlide refersh={refersh} setRefresh={setRefresh} CheckValue={1} />}
                            </div>
                            {tags.length !== 0 ?
                                <>
                                    <div className={Style.tagsTable}>
                                        <Table TableData={TableData} TableHeading={TableHeading} />
                                    </div>
                                </>
                                :
                                <>
                                    No Tags available
                                </>
                            }

                        </div>
                        :
                        <Result
                            status="403"
                            title="403"
                            subTitle="Sorry, you are not authorized to access this page."
                        />
                    }

                </>}


            <ToastContainer />

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure want to delete this tag
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" onClick={() => handleDelete(deleteId)} >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Tag;
