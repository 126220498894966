import { Spin } from 'antd';
import React, { useEffect } from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom';

const ExploreMoreClicks = () => {
    const history = useHistory();

    // console.log("hello");
    const { template, id, cityId, newsletterId, categoryId } = useParams();
    // console.log(news_letter_tracking_id);
    const init = async () => {
        const x = await fetch(`${process.env.REACT_APP_API_URL}/event/explore-more-clicks?news_letter_tracking_id=${newsletterId}`)

        const data = await x.json()
        // console.log(data);
        if (data.message) {
            // return <Redirect to={`${process.env.FRONTEND_URL}/Latest_Weekly_Newsletter/${template}/${id}/${cityId}/${newsletterId}/${categoryId}`} />;
            // history.push(`${process.env.FRONTEND_URL}/Latest_Weekly_Newsletter/${template}/${id}/${cityId}/${newsletterId}/${categoryId}`)
            window.location.href = `/Latest_Weekly_Newsletter/${template}/${id}/${cityId}/${newsletterId}/${categoryId}`
        }
        else {
            alert('URL not working')
        }

    }

    useEffect(() => {
        init()
    }, [newsletterId])



    return (
        <>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh" }}>
                <Spin size='large'/>
            </div>
        </>
    )
}

export default ExploreMoreClicks
